import React from 'react';
import { Link } from 'react-router-dom';
import blogPosts from './data/data';

function Blog() {
  return (
    <div id='blog' className='w-full flex flex-col justify-center items-center'>
      <div className='w-44 p-3 bg-[#116a47] rounded-full flex justify-evenly my-16 mt-32 items-center text-[#f4d7aa] text-2xl'>
        Blog
      </div>
      <div className='w-full flex flex-col sm:flex-row justify-center items-center pb-24 gap-4'>
        {blogPosts.map(post => (
          <Link key={post.id} to={`/blog/${post.id}`} className='w-11/12 sm:w-5/12 md:w-3/12 flex flex-col cursor-pointer'>
            <img src={post.imgSrc} className='w-full h-auto' alt={`Blog ${post.id}`} />
            <div className='text-center shadow-md rounded-lg p-2'>
              <h3 className='text-lg font-bold'>{post.head}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Blog;

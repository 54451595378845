import React from 'react';

function About() {
  return (
    <div id='about' className='relative bg-[#116a47] h-[100vh] '>
      <img
        src='/images/abbg.png'
        className='absolute bottom-0 left-0 w-full pb-0'
        alt="Background"
      />
     <div className='w-full flex justify-center items-center'>
        <div className='absolute top-0 flex flex-col md:flex-row justify-center items-center w-full h-full  items-center'>
   
          <div className='w-full md:w-1/2 relative flex justify-start items-center'>
  
            <div className='relative mt-12 md:mt-0 hidden md:flex'>
              <img src='/images/cup.png' className='w-5/12 mt-[-50px] ms-32' alt="Cup" />
              <img src='/images/coffee.png' className='absolute w-2/3 md:top-8 lg:top-20 left-0' alt="Coffee" />
            </div>
          </div>
          <div className='w-full md:w-1/2 flex flex-col items-center text-left mt-12 md:mt-[-20px]'>
            <h1 className='w-10/12 text-3xl  text-center text-[#ccb994]'>
              About us
            </h1>
            <p className='w-full md:w-10/12 text-xl p-2 pr-0 md:pr-2 text-[#ccb994] p-1'>
            We believe in serving with passion and love. It’s not just about enhancing the taste, it’s about strengthening the bond between people. Every cup we craft reflects our dedication to quality and connection, making your coffee experience truly special. From the carefully selected beans to the final sip, we pour our hearts into every detail, ensuring that whether you're sharing moments with friends or enjoying time for yourself, each visit is memorable.
            </p>
          </div>
        </div>
     </div>
    </div>
  );
}

export default About;

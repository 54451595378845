import React from 'react'
import CarouselComponent from './CarouselComponent'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <div id='menu' className='pt-12 flex flex-col justify-center items-center '>
      <div className='w-44 p-3 bg-[#116a47] rounded-full flex justify-evenly items-center text-[#f4d7aa] text-2xl  mb-12' >
        Menu

      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-2 sm:gap-5 sm:flex-row justify-center w-full">
        <Link to={'/menu'} className='flex flex-col justify-center items-center gap-2 w-full sm:w-5/12 p-4'>
            <img src="/images/menusnack.png" alt="Snacks" className="w-full rounded-xl h-auto object-cover transform hover:scale-95 transition-transform duration-300" />
            <Link to={'/menu'} className="border-2 border-[#116a47] text-[#116a47]  rounded-full py-3 px-6 hover:bg-[#116a47] text-center hover:text-[#f4d7aa]">Snacks</Link>
          </Link>
          <Link to={'/menu'} className='flex flex-col justify-center items-center gap-2 w-full sm:w-5/12 p-4 '>
            <img src="/images/menudrink2.jpg" alt="Coffee" className="w-full  rounded-xl h-auto object-cover  transform hover:scale-95 transition-transform duration-300 " />
            <Link to={'/menu'} className="border-2 border-[#116a47] text-[#116a47]  rounded-full py-3 px-6 hover:bg-[#116a47] hover:text-[#f4d7aa] text-center">Drinks</Link>
          </Link>  </div>

      </div>
    </div>
  )
}

export default Menu
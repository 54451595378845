import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import './Header.css'; // Import the CSS file for the Header
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); // State to track the active link

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = (link) => {
    setIsOpen(false);
    setActiveLink(link); // Set the active link
  };

  return (
    <nav className="header lg:rounded-full w-full bg-[#FFFAF2] shadow-md z-50 lg:mt-[10px] md:mt-[30px]">
      <div className="flex justify-between md:justify-evenly w-full h-16 items-center">
        <div className="flex items-center w-2/12 sm:w-1/12 justify-center">
          <img src='/images/logocafe.png' className='w-full md:w-[90%]' alt="Logo" />
        </div>
        <div className="hidden md:flex md:items-center md:w-7/12">
          <div className="flex justify-center gap-24 items-center w-full">
            <Link to='/' onClick={() => closeNavbar('/')} className={`text-black hover:border-b-4 hover:border-[#116a47]  hover:text-[#116a47] text-sm font-medium hover:text-[#116a47] ${activeLink === '/' ? 'active' : ''}`}>HOME</Link>
            <Link 
  to='/menu' 
  onClick={() => closeNavbar('/menu')} 
  className={`text-black  text-sm font-medium hover:border-b-4 hover:border-[#116a47]  hover:text-[#116a47] ${activeLink === '/menu' ? 'active' : ''}`}
>
  MENU
</Link>            <Link to='/blog' onClick={() => closeNavbar('/blog')} className={`text-black  text-sm font-medium hover:border-b-4 hover:border-[#116a47]  hover:text-[#116a47] hover:text-[#116a47] ${activeLink === '/blog' ? 'active' : ''}`}>BLOG</Link>
          </div>
        </div>
        <div className="flex md:hidden">
          <button
            onClick={toggleNavbar}
            type="button"
            className="inline-flex ml-[-50px] items-center justify-center rounded-md text-gray-400 hover:text-[#116a47] focus:outline-none focus:text-[#116a47]"
            aria-controls="mobile-menu"
            aria-expanded={isOpen}
          >
            <span className="sr-only">Open main menu</span>
            {!isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </button>
        </div>
        <div className="hidden md:flex items-center">
          <a href='#' className='flex justify-center items-center py-7 px-2'><FaFacebook className='text-[#116a47] text-2xl' /></a>
          <a href='#' className='flex justify-center items-center py-7 px-2'><FaInstagram className='text-[#116a47] text-2xl' /></a>
          <a href='#' className='flex justify-center items-center py-7 px-2'><FaWhatsapp className='text-[#116a47] text-2xl' /></a>
        </div>
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
        <div className="pt-2 pb-3 space-y-1">
          <Link to='/' onClick={() => closeNavbar('/')} className={`block px-3 py-2 rounded-md text-center text-sm font-medium text-black hover:text-[#116a47] ${activeLink === '/' ? 'active' : ''}`}>HOME</Link>
          <Link to='/menu' onClick={() => closeNavbar('/menu')} className={`block px-3 py-2 rounded-md text-center text-sm font-medium text-black hover:text-[#116a47] ${activeLink === '/menu' ? 'active' : ''}`}>MENU</Link>
          <Link to='/blog' onClick={() => closeNavbar('/blog')} className={`block px-3 py-2 rounded-md text-center text-sm font-medium text-black hover:text-[#116a47] ${activeLink === '/blog' ? 'active' : ''}`}>BLOG</Link>
          <div className="flex justify-center space-x-2 pt-4">
            <a href='#' className='flex justify-center items-center px-2'><FaFacebook className='text-[#116a47] text-2xl' /></a>
            <a href='#' className='flex justify-center items-center px-2'><FaInstagram className='text-[#116a47] text-2xl' /></a>
            <a href='#' className='flex justify-center items-center px-2'><FaWhatsapp className='text-[#116a47] text-2xl' /></a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;

import React from 'react'
import MenuItems from '../components/MenuItems'

function MenuPage() {
  return (
    <div className=' '>
        <MenuItems/>
    </div>
  )
}

export default MenuPage
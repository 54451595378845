import React from 'react';

const images = [
  '/images/gallery1.png', 
  '/images/gallery-2.png', 
  '/images/gallery-3.png', 
  '/images/gallery-4.jpg', 
  '/images/gallery-5.jpg', 
  '/images/gallery-6.jpg'
];

function ImageGallery() {
  return (
    <div id='gallery' className='flex flex-col justify-center items-center w-full mt-16'>
                      <div className='w-44 p-3 bg-[#116a47] rounded-full flex justify-evenly items-center text-[#f4d7aa] text-2xl  ' >
Gallery

</div>
      <div className="flex flex-wrap justify-center items-center gap-4 w-11/12">
        {images.map((image, index) => {
          let marginTopClass = '';
          switch (index) {
            case 1:
                marginTopClass = 'sm:mt-[100px]';
                break;
            case 3:
            case 5:
              marginTopClass = 'sm:mt-[-100px]';
              break;
            default:
              marginTopClass = 'sm:mt-0';
          }

          return (
            <img 
              key={index} 
              src={image} 
              alt={`Gallery image ${index + 1}`} 
              className={`w-full sm:w-3/12 p-1 rounded-[10%] ${marginTopClass}`} 
            />
          );
        })}
      </div>
    </div>
  );
}

export default ImageGallery;
